import React, { FC } from 'react';
import { useInspectionsVehiclesDamagesLines } from '@/api/Inspections/hooks/useInspectionsVehicles';
import { HUB_METHODS_NAMES, useWebSockets } from '@/hooks';
import { AppSearchResponse, Inspection } from '@/types';
import { DamageDataGrid } from './components/DamageDataGrid';
import { InspectionPageTab } from '../types';

export const Damage: FC<InspectionPageTab> = ({ inspection, onReload }) => {
  const { id } = inspection;

  const {
    isLoading,
    data: damagesData,
    refetch,
  } = useInspectionsVehiclesDamagesLines(id);

  const wsEventHandler = (data: AppSearchResponse<Inspection>) => {
    if (data.entities[0].id === id) {
      console.log('refetch damages trigged by ws');
      refetch();
    }
  };

  useWebSockets(HUB_METHODS_NAMES.SendVehicleInspectionsToUser, wsEventHandler);

  return (
    <DamageDataGrid
      loading={isLoading}
      inspection={inspection}
      dataSource={damagesData?.entities || []}
      onReload={() => {
        onReload?.();
        refetch();
      }}
    />
  );
};
