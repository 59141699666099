import { useTranslate } from '@/translations';
import { AppSearch } from '@/types';
import { StorageKeys } from '@/utils/constants';
import { Button, Dropdown, Form, Input, MenuProps, Modal } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { faAdd, faClose, faEdit, faEllipsis, faTrash, Icon } from '../Icon';

const { confirm: confirmModal } = Modal;

interface QuickViewPanelProps<T> {
  quickViewFormat?: (query: AppSearch<T>) => void;
  appSearchStorage: StorageKeys;
  defaultAppSearch?: AppSearch<T>;
  appSearch: AppSearch<T>;
  changeAppSearch: (query: Partial<AppSearch<T>>) => void;
}
export function QuickViewPanel<T>({
  quickViewFormat,
  appSearchStorage,
  defaultAppSearch,
  appSearch,
  changeAppSearch,
}: QuickViewPanelProps<T>) {
  const [quickViews, setQuickViews] = useState<string[]>([]);
  const [showSaveQuickView, setShowSaveQuickView] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslate();

  const loadStorageQuickViews = useCallback(() => {
    const prefix = `${appSearchStorage}-`;
    const views = Object.keys(window.localStorage)
      .filter((key) => key.includes(prefix))
      .map((key) => key.replace(prefix, ''));
    setQuickViews(views);
  }, [appSearchStorage]);

  useEffect(() => {
    loadStorageQuickViews();
  }, [loadStorageQuickViews]);

  const onDeleteQuickView = useCallback(
    (key: string) => {
      confirmModal({
        title: 'Confirm',
        content: 'Do you want to delete the quick view?',
        onOk() {
          window.localStorage.removeItem(`${appSearchStorage}-${key}`);
          loadStorageQuickViews();
        },
      });
    },
    [appSearchStorage, loadStorageQuickViews],
  );

  const quickViewMenuItems: MenuProps['items'] = useMemo(() => {
    const items: MenuProps['items'] = [];

    items.push(
      ...quickViews.map((o) => ({
        key: o,
        label: (
          <div className="w-100 d-flex align-items-center gap-2">
            <span className="flex-fill">{o}</span>
            {appSearch.name !== o ? (
              <Icon
                icon={faTrash}
                className="flex-1"
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onDeleteQuickView(o);
                }}
              />
            ) : null}
          </div>
        ),
      })),
    );

    const manageItems = [
      {
        key: 'manage-add',
        label: 'Add as new quick view',
        icon: <Icon icon={faAdd} />,
      },
      {
        key: 'manage-update',
        label: 'Update current quick view',
        icon: <Icon icon={faEdit} />,
      },
      {
        key: 'manage-clear',
        label: 'Clear filters & sort',
        icon: <Icon icon={faClose} />,
      },
    ];

    const shouldShowManage = !!appSearch.filters || !!appSearch.sortField;
    if (items.length > 0 && shouldShowManage) {
      items.push({ type: 'divider' });
    }
    if (shouldShowManage) {
      items.push(...manageItems);
    }
    return items;
  }, [
    appSearch.filters,
    appSearch.name,
    appSearch.sortField,
    onDeleteQuickView,
    quickViews,
  ]);

  const onMenuClick = useCallback(
    (menuKey: string) => {
      if (menuKey === 'manage-add') {
        form.resetFields();
        setShowSaveQuickView(true);
      } else if (menuKey === 'manage-update') {
        window.localStorage.setItem(
          `${appSearchStorage}-${appSearch.name}`,
          JSON.stringify(appSearch),
        );
        loadStorageQuickViews();
      } else if (menuKey === 'manage-clear') {
        changeAppSearch(
          defaultAppSearch
            ? { ...defaultAppSearch }
            : {
                name: undefined,
                search: undefined,
                sortField: undefined,
                sortOrder: undefined,
                filters: undefined,
              },
        );
      } else {
        const query = JSON.parse(
          window.localStorage.getItem(`${appSearchStorage}-${menuKey}`) || '{}',
        ) as AppSearch<T>;
        quickViewFormat?.(query);
        changeAppSearch(query);
      }
    },
    [
      appSearch,
      appSearchStorage,
      changeAppSearch,
      defaultAppSearch,
      form,
      loadStorageQuickViews,
      quickViewFormat,
    ],
  );

  const onAddQuickView = useCallback(
    (values: any) => {
      if (quickViews.includes(values.name)) {
        form.setFields([{ name: 'name', errors: ['The name already exist.'] }]);
        return;
      }
      appSearch.name = values.name;
      window.localStorage.setItem(
        `${appSearchStorage}-${values.name}`,
        JSON.stringify(appSearch),
      );
      loadStorageQuickViews();
      setShowSaveQuickView(false);
    },
    [appSearch, appSearchStorage, form, loadStorageQuickViews, quickViews],
  );

  return (
    <>
      <Dropdown
        menu={{
          items: quickViewMenuItems,
          onClick: (e) => onMenuClick(e.key),
        }}
      >
        <Button size="large" style={{ minWidth: 150 }}>
          <div className="w-100 d-flex align-items-center gap-2">
            <span
              className={`text-start flex-fill${
                appSearch?.name ? '' : ' text-muted'
              }`}
            >
              {appSearch?.name ? appSearch?.name : t('selectQuickView')}
            </span>
            <Icon icon={faEllipsis} className="flex-1" />
          </div>
        </Button>
      </Dropdown>

      <Modal
        open={showSaveQuickView}
        title={t('saveView')}
        onCancel={() => setShowSaveQuickView(false)}
        maskClosable
        footer={null}
      >
        <Form form={form} onFinish={onAddQuickView}>
          <Form.Item
            label={t('quickViewName')}
            name="name"
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item className="d-flex justify-content-end">
            <Button type="primary" htmlType="submit">
              {t('save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
