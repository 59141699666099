/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { DefaultOptionType } from 'antd/es/select';
import { Form, Input, InputNumber, Select } from 'antd';
import { CellAdditionProps, EditableContext } from './types';

interface EditableCellProps<T> extends CellAdditionProps<T> {
  record: T;
  onUpdate?: (oldData: T, newData: Partial<T>) => void;
}
export const EditableCell: React.FC<
  React.PropsWithChildren<EditableCellProps<any>>
> = ({
  dataIndex,
  editable,
  exportable,
  renderType,
  optionIndex,
  renderOptions,
  rules,
  exportRender,
  record,
  onUpdate,
  children,
  ...restProps
}) => {
  const form = useContext(EditableContext)!;
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const keyField = optionIndex || dataIndex;
  const isEditable =
    typeof editable === 'function' ? editable(record) : editable;

  useEffect(() => {
    if (!renderOptions) return;
    const list =
      typeof renderOptions === 'function'
        ? renderOptions(record)
        : renderOptions;
    setOptions(list);
  }, [record, renderOptions]);

  useEffect(() => {
    if (!record) return;
    form.setFieldsValue({ [keyField]: record[keyField] });
  }, [record, keyField, form]);

  const save = async () => {
    const values: any = await form.validateFields();
    const newData = { [keyField]: values[keyField] };
    onUpdate?.(record, newData);
  };

  return (
    <td {...restProps}>
      {isEditable ? (
        <Form.Item
          style={{ margin: 0 }}
          name={keyField as string}
          rules={rules}
        >
          {renderType === 'select' ? (
            <Select options={options} onChange={save} />
          ) : renderType === 'number' ? (
            <InputNumber onPressEnter={save} />
          ) : (
            <Input onPressEnter={save} />
          )}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
