import React, { FC } from 'react';
import { getValueOrNA } from '@/ui/components/NotApplicable';
import { useTranslate } from '@/translations';

import { Descriptions } from 'antd';
import { fieldRenderers } from './helpers';
import { InspectionCardProps } from './types';

export const InspectionCard: FC<InspectionCardProps> = ({
  data,
  fields,
  column,
  layout,
  size,
}) => {
  const { t } = useTranslate();

  return (
    <Descriptions
      layout={layout || 'vertical'}
      column={column || 3}
      size={size || 'middle'}
      items={Object.entries(fields).map(([field, label]) => {
        const value = fieldRenderers[field]
          ? fieldRenderers[field](data, t)
          : getValueOrNA(field)({ data });

        return {
          key: field,
          label,
          children: value,
        };
      })}
    />
  );
};
