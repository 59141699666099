import React from 'react';
import { GetRef } from 'antd';
import Form, { Rule } from 'antd/es/form';
import { DefaultOptionType } from 'antd/es/select';
import { ColumnType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';

type FormInstance = GetRef<typeof Form>;
export const EditableContext = React.createContext<FormInstance | null>(null);
export type RenderType = 'input' | 'select' | 'number' | 'date';

export interface CellAdditionProps<T> {
  dataIndex: keyof T;
  editable?: boolean | ((record: T) => boolean);
  exportable?: boolean | ((record: T) => boolean);
  filter?: boolean;
  renderType?: RenderType;
  renderFormat?: string;
  optionIndex?: keyof T;
  renderOptions?: ((data: T) => DefaultOptionType[]) | DefaultOptionType[];
  filterOptions?: ColumnFilterItem[];
  rules?: Rule[];
  exportTitle?: string;
  exportRender?: (value: any, data: T) => string;
}

export type CustomColumns<T> = (ColumnType<T> & CellAdditionProps<T>)[];
