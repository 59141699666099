import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { notify } from '@/ui';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import { faDownload, faTimeline, Icon } from '@/ui/components/Icon';
import { useTranslate } from '@/translations';
import { InspectionStatusMap } from '@/utils/constants';
import {
  currencyFormatted,
  dateConverter,
  checkDataChanged,
  sectorConverter,
} from '@/utils/helpers';
import { inspections } from '@/api/Inspections';
import {
  useUpdateInspectionsVehicleDamages,
  useDeleteInspectionsVehiclesDamagesLines,
  useInspectionsVehicleRepairActions,
  useUnDeleteInspectionsVehiclesDamagesLines,
  useBatchUpdateInspectionsVehicleDamagesStatus,
} from '@/api/Inspections/hooks/useInspectionsVehicles';
import { ActionsPopup } from '@/modules/Inspection/components/tabs/Damage/components/ActionsPopup';
import { AddDamageLinePopup } from '@/modules/Inspection/components/tabs/Damage/components/DamageLinePopup/AddDamageLine';
import { EditDamageLinePopup } from '@/modules/Inspection/components/tabs/Damage/components/DamageLinePopup/EditDamageLinePopup';
import { useDataGridExpandedState } from '@/modules/Inspection/components/tabs/Damage/components/useDataGridExpandedState';
import {
  AuditActionType,
  AuditLog,
  AuditLogEvent,
  Damage,
  SeverityAction,
} from '@/types';
import { queryClient } from '@/config/reactQuery';
import { QUERY_KEYS } from '@/api/queryKeys';

import { Button, Timeline, Tooltip } from 'antd';
import { CustomColumns, CustomTable } from '@/ui/components/CustomTable';
import { DamageMasterDetail } from './MasterDetail';
import {
  AuthorizationStatus,
  DamageDataGridProps,
  DamageDataGridDataSource,
} from './types';

const INITIAL_VALUES = {
  id: null,
  status: AuthorizationStatus.Default,
};

export const DamageDataGrid: FC<DamageDataGridProps> = ({
  loading,
  inspection,
  dataSource,
  onReload,
}) => {
  const { t } = useTranslate();
  // leave this hook here, to avoid data clearing when re-rendering MasterDetail
  const masterDetailsDataGridProps = useDataGridExpandedState();

  const { data: repairActionsData } = useInspectionsVehicleRepairActions();

  const [severityActionsData, setSeverityActionsData] = useState<{
    [partCode: string]: SeverityAction[];
  }>({});

  const severityOptions = useMemo(() => {
    const options: SeverityAction[] = [];
    const partCodes = Array.from(
      new Set(dataSource.map((item) => item.partCode)),
    );
    partCodes.forEach((partCode) => {
      const severities = severityActionsData[partCode] || [];
      severities.forEach((severity) => {
        if (!options.some((o) => o.id === severity.id)) options.push(severity);
      });
    });
    return options;
  }, [dataSource, severityActionsData]);

  useEffect(() => {
    // TODO: it's a slow performance place, we will do a request for each damage line
    // GET related severities by partCode and damageType
    dataSource.forEach(async (item) => {
      const { data } = await inspections.getInspectionsVehicleSeverityActions(
        item.partCode,
        item.damageTypeDesc,
      );

      setSeverityActionsData((prevState) => ({
        ...prevState,
        [item.partCode]: data.entities,
      }));
    });
  }, [dataSource]);

  const [comment, setComment] = useState<string>('');
  const [isPopupVisible, setPopupVisibility] = useState<boolean>(false);
  const [authorization, setAuthorization] = useState<{
    id: number | null;
    status: AuthorizationStatus;
    backToDefault?: boolean;
  }>(INITIAL_VALUES);

  const {
    mutateAsync: updateVehicleDamageMutateAsync,
    isLoading: updateLoading,
  } = useUpdateInspectionsVehicleDamages();
  const {
    mutateAsync: batchUpdateVehicleDamageStatusMutateAsync,
    isLoading: batchUpdateLoading,
  } = useBatchUpdateInspectionsVehicleDamagesStatus();
  const {
    mutateAsync: deleteVehicleDamageLineMutateAsync,
    isLoading: deleteLoading,
  } = useDeleteInspectionsVehiclesDamagesLines();
  const {
    mutateAsync: unDeleteVehicleDamageLineMutateAsync,
    isLoading: unDeleteLoading,
  } = useUnDeleteInspectionsVehiclesDamagesLines();

  const togglePopup = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
  }, [isPopupVisible]);

  const handleAuthorizationStatus = useCallback(
    (id: number, status: AuthorizationStatus, backToDefault?: boolean) => {
      setAuthorization({
        id,
        status,
        backToDefault,
      });
      togglePopup();
    },
    [togglePopup],
  );

  const handleSaveClick = async () => {
    if (!authorization.id) return;

    try {
      await updateVehicleDamageMutateAsync({
        id: authorization.id,
        authorizationNotes: {
          isChanged: true,
          value: comment,
        },
        authorizationStatus: {
          isChanged: true,
          value: authorization.backToDefault
            ? AuthorizationStatus.Default
            : authorization.status,
        },
        severityId: {
          isChanged: false,
        },
        repairActionId: {
          isChanged: false,
        },
      });
      onReload();
      // updated current damage status
      await queryClient.invalidateQueries([QUERY_KEYS.damages]);
      // update inspection (costs?)
      await queryClient.invalidateQueries([QUERY_KEYS.inspectionsVehicle]);
    } catch (error) {
      console.log('handleSaveClick: ', { error });
    }

    setComment('');
    togglePopup();
  };

  const onRowUpdating = useCallback(
    async (oldData: Damage, newData: Partial<Damage>) => {
      try {
        await updateVehicleDamageMutateAsync({
          id: oldData.id,
          authorizationNotes: {
            isChanged: false,
          },
          authorizationStatus: {
            isChanged: false,
          },
          severityId: {
            isChanged: !!newData.severityId,
            value: newData.severityId,
          },
          repairActionId: {
            isChanged: !!newData.repairActionCode,
            value: repairActionsData?.entities?.find(
              (o) => o.repairActionCode === newData.repairActionCode,
            )?.id,
          },
        });
        onReload();
        const field = newData.severityDesc ? 'Severity' : 'Repair action';

        notify(
          {
            message: `${field} updated!`,
            position: {
              my: 'top right',
              at: 'top right',
            },
          },
          'success',
          3000,
        );
      } catch (error) {
        console.log('onRowUpdated: ', { error });
        const serverMessage = (error as any).response.data?.entities?.[0]
          ?.message;

        notify(
          {
            message: serverMessage || (error as Error).message,
            position: {
              my: 'top right',
              at: 'top right',
            },
          },
          'error',
          3000,
        );
      }
    },
    [updateVehicleDamageMutateAsync, onReload, repairActionsData],
  );

  const handleDeleteDamageLine = useCallback(
    async (damageLineId: number) => {
      try {
        await deleteVehicleDamageLineMutateAsync({
          damageLineId,
          inspectionId: inspection.id,
        });
        onReload();
      } catch (error) {
        console.log('handleDeleteDamageLine: ', { error });
      }
    },
    [deleteVehicleDamageLineMutateAsync, onReload, inspection],
  );

  const handleUnDeleteDamageLine = useCallback(
    async (damageLineId: number) => {
      try {
        await unDeleteVehicleDamageLineMutateAsync({
          damageLineId,
          inspectionId: inspection.id,
        });
        onReload();
      } catch (error) {
        console.log('handleUnDeleteDamageLine: ', { error });
      }
    },
    [unDeleteVehicleDamageLineMutateAsync, onReload, inspection],
  );

  const handleBatchChangeStatus = useCallback(
    async (status: number) => {
      try {
        await batchUpdateVehicleDamageStatusMutateAsync({
          inspectionId: inspection.id,
          authorizationStatus: status,
        });
        onReload();
      } catch (error) {
        console.log('handleUnDeleteDamageLine: ', { error });
      }
    },
    [batchUpdateVehicleDamageStatusMutateAsync, inspection.id, onReload],
  );

  const handleCancelClick = () => {
    setComment('');
    setAuthorization(INITIAL_VALUES);
    togglePopup();
  };

  const getColorForAuthorizationStatus = (data: Damage) => {
    const { isNew, isDeleted, originalData, authorizationStatus } = data;
    let damageStatusClass: string | undefined;
    if (isDeleted) damageStatusClass = 'row-deleted';
    else if (
      checkDataChanged(data, originalData, [
        'AreaCode',
        'PartCode',
        'DamageCauseCode',
        'Sector',
        'DamageConditionCode',
        'RepairActionCode',
        'InternalGradingId',
        'SeverityId',
        'WorkCenterCode',
        'ResponsibilityCode',
        'Note',
      ])
    )
      damageStatusClass = 'row-changed';
    else if (isNew) damageStatusClass = 'row-new';

    const colorMap: { [key: string]: string } = {
      Approved: 'bg-lightGreen',
      Rejected: 'bg-lightRed',
    };
    const color = colorMap[authorizationStatus];
    return [damageStatusClass, color].join(' ');
  };

  const [createDamageLinePopupState, setCreateDamageLinePopupState] =
    useState(false);
  const [editDamageLinePopupState, setEditDamageLinePopupState] =
    useState<DamageDataGridDataSource>();

  const toggleCreateDamageLinePopup = useCallback(
    () => setCreateDamageLinePopupState(!createDamageLinePopupState),
    [createDamageLinePopupState],
  );

  const toggleEditDamageLinePopup = useCallback(
    (data?: DamageDataGridDataSource) => setEditDamageLinePopupState(data),
    [],
  );

  const isAllowedToUpdate = useMemo(
    () =>
      [
        InspectionStatusMap.Inspected,
        InspectionStatusMap['Awaiting Authorisation'],
        InspectionStatusMap['Requires Parts Pricing'],
        InspectionStatusMap['Requires Review'],
        InspectionStatusMap['Requires Re-submission Review'],
      ].includes(inspection.inspectionStatus),
    [inspection],
  );

  const isAuthorised = useMemo(
    () => inspection.inspectionStatus === InspectionStatusMap.Authorised,
    [inspection],
  );

  const renderImageFileUrlCell = useCallback(
    (value: any, id: number) =>
      value?.length ? (
        <div className="d-flex flex-column">
          {value.map((imageFileUrl: string, idx: number) => (
            <a
              key={imageFileUrl}
              href={imageFileUrl}
              download={`damage id: ${id}, image #${idx + 1}`}
              aria-label="Download"
              className="my-1"
            >
              {idx + 1}. <Icon icon={faDownload} />
            </a>
          ))}
        </div>
      ) : null,
    [],
  );

  const renderAuditLogsCell = useCallback(
    (auditLogs) =>
      auditLogs?.length ? (
        <Tooltip
          overlayClassName="timeline-tooltip"
          overlayStyle={{ minWidth: 400 }}
          overlayInnerStyle={{ paddingTop: 30, paddingRight: 10 }}
          color="#fff"
          zIndex={1000}
          placement="left"
          title={
            <Timeline
              items={auditLogs.map((o: AuditLog) => {
                const eventType =
                  AuditLogEvent[
                    o.eventType as unknown as keyof typeof AuditLogEvent
                  ];
                return {
                  color:
                    o.actionType === AuditActionType.Create
                      ? 'green'
                      : o.actionType === AuditActionType.Update
                      ? 'orange'
                      : o.actionType === AuditActionType.Delete
                      ? 'red'
                      : o.actionType === AuditActionType.UnDelete
                      ? 'purple'
                      : 'gray',
                  children: (
                    <>
                      {o.userName}{' '}
                      {o.actionType[0].toLocaleLowerCase() +
                        o.actionType.slice(1)}{' '}
                      <b>{eventType}</b> at {dateConverter(o.createAt, 'lll')}
                    </>
                  ),
                };
              })}
            />
          }
        >
          <div className="cursor-pointer">
            <Icon icon={faTimeline} className="hoverable-icon" />
          </div>
        </Tooltip>
      ) : null,
    [],
  );

  const renderActionsCell = useCallback(
    (data: Damage) => {
      const { id, isDeleted, authorizationStatusNum } = data;

      return isAuthorised || !isAllowedToUpdate ? null : (
        <StyledLinkContainer>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {isDeleted ? (
              <StyledLink onClick={() => handleUnDeleteDamageLine(id)}>
                {t('unDelete')}
              </StyledLink>
            ) : [
                AuthorizationStatus.Approved,
                AuthorizationStatus.Rejected,
              ].includes(authorizationStatusNum) ? (
              <StyledLink
                onClick={() =>
                  handleAuthorizationStatus(id, authorizationStatusNum, true)
                }
              >
                {t(
                  authorizationStatusNum === AuthorizationStatus.Approved
                    ? 'unApprove'
                    : 'unReject',
                )}
              </StyledLink>
            ) : (
              <>
                <StyledLink
                  onClick={() =>
                    handleAuthorizationStatus(id, AuthorizationStatus.Approved)
                  }
                >
                  {t('approve')}
                </StyledLink>

                <StyledLink
                  onClick={() =>
                    handleAuthorizationStatus(id, AuthorizationStatus.Rejected)
                  }
                >
                  {t('reject')}
                </StyledLink>

                <StyledLink onClick={() => toggleEditDamageLinePopup(data)}>
                  {t('edit')}
                </StyledLink>

                {!isDeleted && (
                  <StyledLink onClick={() => handleDeleteDamageLine(id)}>
                    {t('delete')}
                  </StyledLink>
                )}
              </>
            )}
          </div>
        </StyledLinkContainer>
      );
    },
    [
      handleAuthorizationStatus,
      toggleEditDamageLinePopup,
      handleDeleteDamageLine,
      handleUnDeleteDamageLine,
      t,
      isAllowedToUpdate,
      isAuthorised,
    ],
  );

  const columns: CustomColumns<Damage> = [
    {
      dataIndex: 'damageAreaDesc',
      title: t('location'),
      width: 150,
      fixed: 'left',
      filter: true,
    },
    {
      dataIndex: 'damagePartDesc',
      title: t('partComp'),
      filter: true,
    },
    {
      dataIndex: 'damageTypeDesc',
      title: t('damageType'),
      filter: true,
    },
    {
      dataIndex: 'sector',
      title: t('sector'),
      render: (value) => sectorConverter(value),
      filter: true,
    },
    {
      dataIndex: 'severityDesc',
      title: t('severity'),
      width: 160,
      editable: (data) => (data.isDeleted ? false : isAllowedToUpdate),
      renderType: 'select',
      optionIndex: 'severityId',
      renderOptions: (data: Damage) =>
        severityActionsData?.[data?.partCode]?.map((o) => ({
          label: o.description,
          value: o.id,
        })) || [],
      filterOptions: severityOptions.map((o) => ({
        text: o.description,
        value: o.id,
      })),
      filter: true,
    },
    {
      dataIndex: 'repairActionDesc',
      title: t('repairMethod'),
      width: 160,
      editable: (data) => (data.isDeleted ? false : isAllowedToUpdate),
      renderType: 'select',
      optionIndex: 'repairActionCode',
      renderOptions: repairActionsData?.entities?.map((o) => ({
        label: o.repairActionDesc,
        value: o.repairActionCode,
      })),
      filter: true,
    },
    {
      dataIndex: 'internalGradingPoints',
      title: t('internalGrade'),
      filter: true,
    },
    {
      dataIndex: 'workCenterDesc',
      title: t('workCentre'),
      filter: true,
    },
    {
      dataIndex: 'responsibilityDesc',
      title: t('responsibility'),
      filter: true,
    },
    {
      dataIndex: 'valueForGrading',
      title: t('gradePoints'),
      filter: true,
    },
    {
      dataIndex: 'lineCost',
      title: t('lineCost'),
      render: (value) => currencyFormatted(value),
      filter: true,
    },
    {
      dataIndex: 'note',
      title: t('note'),
      width: 100,
      filter: true,
    },
    {
      dataIndex: 'damageImageFileUrls',
      render: (value, data) => renderImageFileUrlCell(value, data.id),
      align: 'center',
      width: 60,
      exportable: false,
    },
    {
      dataIndex: 'auditLogs',
      render: (value) => renderAuditLogsCell(value),
      align: 'center',
      width: 40,
      exportable: false,
    },
    {
      dataIndex: 'id',
      render: (_, data) => renderActionsCell(data),
      align: 'center',
      width: 100,
      fixed: 'right',
      exportable: false,
    },
  ];

  const masterDetailRender = useCallback(
    (data: Damage) => (
      <DamageMasterDetail
        inspectionId={inspection.id}
        vehicleDamageId={data.id}
        disabled={
          !isAllowedToUpdate || data.isDeleted || !!data.authorizationStatusNum
        }
        note={data.note}
        authorizationNotes={data.authorizationNotes}
        masterDetailsDataGridProps={masterDetailsDataGridProps}
      />
    ),
    [inspection.id, isAllowedToUpdate, masterDetailsDataGridProps],
  );

  return (
    <>
      <ActionsPopup
        isPopupVisible={isPopupVisible}
        togglePopup={togglePopup}
        comment={comment}
        setComment={setComment}
        handleSaveClick={handleSaveClick}
        handleCancelClick={handleCancelClick}
        authorizationStatus={authorization.status}
        backToDefault={authorization.backToDefault}
      />

      {isAllowedToUpdate && (
        <AddDamageLinePopup
          isPopupVisible={createDamageLinePopupState}
          onTogglePopup={toggleCreateDamageLinePopup}
          inspection={inspection}
          onSubmited={onReload}
        />
      )}
      {isAllowedToUpdate && editDamageLinePopupState && (
        <EditDamageLinePopup
          isPopupVisible={!!editDamageLinePopupState}
          onTogglePopup={() => toggleEditDamageLinePopup(undefined)}
          onSubmited={onReload}
          inspection={inspection}
          damageLine={editDamageLinePopupState!}
        />
      )}

      <CustomTable
        className="separateRowsTable"
        rowKey="id"
        size="small"
        loading={
          loading ||
          updateLoading ||
          batchUpdateLoading ||
          deleteLoading ||
          unDeleteLoading
        }
        dataSource={dataSource}
        columns={columns}
        headerActions={{
          position: 'right',
          align: 'start',
          children: (
            <>
              <Button
                onClick={() =>
                  handleBatchChangeStatus(AuthorizationStatus.Approved)
                }
              >
                {t('approveAll')}
              </Button>
              <Button
                onClick={() =>
                  handleBatchChangeStatus(AuthorizationStatus.Rejected)
                }
              >
                {t('rejectAll')}
              </Button>
            </>
          ),
        }}
        onAdd={toggleCreateDamageLinePopup}
        onUpdate={(oldData, newData) => onRowUpdating(oldData, newData)}
        rowClassName={(record) => getColorForAuthorizationStatus(record)}
        scroll={{ x: 1600, y: '50vh' }}
        expandable={{
          expandedRowRender: (data) => masterDetailRender(data),
        }}
        pagination={false}
        exporting
        exportFileName="Damage Lines"
      />
    </>
  );
};
