import { Statuses } from './types';
import {
  Inspection,
  Notes,
  WheelsAndTyres,
  Checklist,
  Damage,
  Documents,
  Charges,
  Images,
  PointInTimeReports,
  EventViewer,
} from './components/tabs';

export const tabs = [
  {
    title: 'inspection',
    component: Inspection,
  },
  {
    title: 'notes',
    component: Notes,
  },
  {
    title: 'wheelsAndTyres',
    component: WheelsAndTyres,
  },
  {
    title: 'checklist',
    component: Checklist,
  },
  {
    title: 'damage',
    component: Damage,
  },
  {
    title: 'documents',
    component: Documents,
  },
  {
    title: 'charges',
    component: Charges,
  },
  {
    title: 'images',
    component: Images,
  },
  {
    title: 'pointInTimeReports',
    component: PointInTimeReports,
  },
  {
    title: 'eventViewer',
    component: EventViewer,
  },
];

export const fields = {
  basic: {
    regNumber: 'Reg Number',
    manufacturer: 'Make',
    model: 'Model',
    vin: 'VIN',
    colour: 'Color',
  },
  bannerBasic: {
    manufacturer: 'Make',
    model: 'Model',
    vin: 'VIN',
  },
  inspection: {
    inspectionDate: 'Inspection date',
    inspectionTime: 'Inspection time', // inspection date/time comes in inspectionDate field
    inspector: 'Inspector',
    inspectionLocation: 'Inspected At',
    id: 'Inspection ID',
  },
  bannerInspection: {
    inspectionDateTime: 'Inspection date',
    inspectionLocation: 'Inspected At',
    inspectionStatus: 'Inspection status',
  },
  inspectionStatus: {
    inspectionStatus: 'Inspection status',
    approvalDateTime: 'Approval date',
  },
};

export const getPossibleStatues = (statuses: Statuses[], id: number) =>
  statuses.find((status) => status.id === id)?.next;
