import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  useInspectionsVehiclesSummaries,
  useInspectionsVehiclesNotes,
  useInspectionsVehiclesMechanicalsNotes,
} from '@/api/Inspections';
import { PageContainer } from '@/ui/components/Page';
import { Loader } from '@/ui/components/Loader';
import { useTranslate } from '@/translations';

import { Col, Row } from 'antd';
import { CustomCard } from '@/ui/components/CustomCard';
import { LightBoxItem } from '@/ui/components/ImagesLightBox';
import { Gallery } from 'react-photoswipe-gallery';
import { InspectionTabCard } from '../../InspectionTabCard';
import { InspectionPageTab } from '../types';

export const Inspection: FC<InspectionPageTab> = ({ inspection }) => {
  const { t } = useTranslate();
  const { id } = useParams();
  const inspectionNotes = useInspectionsVehiclesNotes(Number(id));
  const mechanicalsNotes = useInspectionsVehiclesMechanicalsNotes(Number(id));
  const summaries = useInspectionsVehiclesSummaries(Number(id));

  if (
    summaries.isLoading ||
    inspectionNotes.isLoading ||
    mechanicalsNotes.isLoading ||
    !summaries.data ||
    !inspectionNotes.data ||
    !mechanicalsNotes.data
  )
    return <Loader />;

  const { id: _inspectionNoteId, ...inspectionNote } =
    inspectionNotes.data.entities[0] || {};
  const { id: _mechanicalNoteId, ...mechanicalNote } =
    mechanicalsNotes.data.entities[0] || {};
  const { id: _summaryId, ...summary } = summaries.data.entities[0] || {};

  return (
    <PageContainer>
      <Row gutter={16}>
        <Col xs={24} md={12} lg={6}>
          <div className="fw-semibold mb-3">{t('odometer')}</div>
          <CustomCard>
            {inspection.mileage} {t('miles')}
            <Gallery>
              <LightBoxItem
                image={{
                  imageFileUrl: inspection.odometerImageFileName,
                  thumbnailImageUrl: inspection.odometerResizedImageUrl,
                }}
              />
            </Gallery>
          </CustomCard>
        </Col>
        <Col xs={24} md={12} lg={6}>
          <InspectionTabCard
            header={t('inspectionNotes')}
            data={inspectionNote}
            noDataMessage={t('noNotes')}
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <InspectionTabCard
            header={t('mechanicalInspectionNotes')}
            data={mechanicalNote}
            noDataMessage={t('noNotes')}
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <InspectionTabCard header={t('summary')} data={summary} />
        </Col>
      </Row>
    </PageContainer>
  );
};
